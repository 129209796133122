.a-text {
    &-28x36 {
        @include font-base(28, 36);
    }

    &-24x32 {
        @include font-base(24, 32);
    }

    &-20x28 {
        @include font-base(20, 28);
    }

    &-18x24 {
        @include font-base(18, 24);
    }

    &-18x24-2 {
        @include font-base(18, 24);
        @include mobile-tablet {
            @include font-base(20, 28);
        }
    }

    &-16x19 {
        @include font-base(16, 19);
    }

    &-16x22 {
        @include font-base(16, 22);
    }
        
    &-14x20 {
        @include font-base(14, 20); 
    }

    &-12x18 {
        @include font-base(12, 18);
    }

    &-40x48 {
        @include font-base(40, 48);
    }

    @include letter-spacing();
    @include style-color();
    @include style-general();
    @include style-font-weight();
}
