.a-icon {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: block;

    &-16 {
        height: rem(16);
        width: rem(16);
    }

    &-24 {
        height: rem(24);
        width: rem(24);
    }

    &-20 {
        height: rem(20);
        width: rem(20);
    }

    &-32 {
        height: rem(32);
        width: rem(32);
    }

    &-40 {
        height: rem(40);
        width: rem(40);
    }
  
   
    &-error {
        background-image: url('~assets/icons/ic_error.svg');
    }

    &-close {
        background-image: url('~assets/icons/ic_close.svg');
    };

    &-link {
        background-image: url('~assets/icons/ic_link.svg');
    }

    &-arrowRight {
        background-image: url('~assets/icons/ic_arrow_right.svg');
    }

    &-arrowLeft {
        background-image: url('~assets/icons/ic_arrow_left.svg');
    }

    &-checkList {
        background-image: url('~assets/icons/ic_checklist.svg');
    }

    &-addLocationRounded {
        background-image: url('~assets/icons/ic_add_location_rounded.svg');
    }

    &-mapDoctor {
        background-image: url('~assets/icons/ic_map_doctor.svg');
    }

    &-blackCheck {
        background-image: url('~assets/icons/ic_check_black.svg');
    }

    &-downloadFile {
        background-image: url('~assets/icons/ic_file_download.svg');
    }
}
