.o-modalSelectPrinter {
    &_itemPrinter {
        margin-bottom: rem(20);
        @include small-mobile {
            margin-bottom: rem(16); 
        }

        &:first-child {
            margin-top: rem(32);
            @include small-mobile {
                margin-top: rem(16); 
            }
        }

        &:last-child {
            margin-bottom: rem(40);
            @include small-mobile {
                margin-bottom: rem(16); 
            }
        }
    }

    &_button {
        margin: auto;
        max-width: rem(151);
    }
}
