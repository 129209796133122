.p-home {
    display: flex;

    @include desktop-down {
        display: block;
    }

    &_sidebar {
        position: relative;
        width: 25%;
        background-color: $white;
        display: flex;
        flex-direction: column;
        flex-shrink: 0;

        @include desktop-down {
            height: rem(230);
        }

        @include tablet-desktop {
            width: 100%;
        }

        @include mobile-down {
            width: 100%;
        }

        &_top {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: rem(36);
            padding-bottom: rem(18);
            flex-shrink: 0;

            @include desktop-down {
                height: rem(128);
            }

            @include tablet-desktop {
                position: absolute;
                width: 100%;
                top: 0;
                left: 0;
                z-index: 1;
                justify-content: space-between;
                align-items: start;
                padding: 0;
            }
        }

        &_logo1 {
            margin-right: rem(16);
            max-width: 45.56%;

            img {
                width: 100%;
            }

            @include tablet-desktop {
                display: none;
            }
        }

        &_logo2 {
            margin-left: rem(16);
            max-width: 21%;

            img {
                width: 100%;
            }

            @include tablet-desktop {
                display: none;
            }
        }


        &_logo3 {
            height: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: left;
            }

            @include desktop-up {
                display: none;
            }

            @include mobile-down {
                display: none;
            }
        }

        &_logo4 {
            margin-right: rem(60);
            align-self: center;

            img {
                width: 100%;
                max-width: rem(160);

                @include desktop-down {
                    max-width: rem(140);
                }
            }

            @include desktop-up {
                display: none;
            }

            @include mobile-down {
                display: none;
            }
        }

        &_line {
            margin-bottom: rem(12);
            height: rem(6);
            background-color: $maroon-x11;

            @include tablet-desktop {
                display: none;
            }
        }

        &_steps {
            position: relative;
            flex-grow: 1;
            background-color: $french-plum;
            padding: rem(68) rem(32) rem(68) rem(48);

            @include desktop-down {
                padding: rem(40) rem(40) rem(16);
            }

            @include tablet-desktop {
                display: flex;
                width: 100%;
                align-items: flex-end;
            }

            @include mobile-down {
                padding: rem(24) 0;
            }

            &::before {
                content: '';
                position: absolute;
                width: 24%;
                height: 21.5%;
                background: url('~assets/images/makeup-top.svg');
                background-position: left bottom;
                right: 0;
                top: 0;
                z-index: 1;
                mix-blend-mode: screen;

                @include desktop-down {
                    width: 18%;
                    height: 100%;
                    background-position: left;
                }
            }

            &::after {
                content: '';
                position: absolute;
                width: 89%;
                height: 23%;
                background: url('~assets/images/makeup-bottom.svg') no-repeat;
                background-position: top right;
                left: 0;
                bottom: 0;
                z-index: 1;
                mix-blend-mode: screen;

                @include desktop-down {
                    width: 40%;
                    height: 35.7%;
                }
            }
        }
    }

    &_assignments {
        position: relative;
        flex-grow: 1;
        padding: rem(40);

        @include desktop-down {
            padding: rem(30) rem(40);
        }

        @include mobile-down {
            padding: rem(40) rem(16) rem(60);
        }

        &_group {
            display: flex;
            width: 100%;
            margin-top: rem(40);
            padding: rem(20) rem(58);
            background-color: $white;
            border-radius: rem(12);

            @include kiosk {
                padding: rem(20) rem(24);
            }

            @include mobile-down {
                display: block;
                padding: 0;
                background-color: transparent;
            }
        }

        &_gender {
            flex-grow: 1;

            &-flex {
                display: flex;
                width: 100%;
                margin-top: rem(14);

                .m-radioImage {
                    flex-basis: 50%;

                    &:last-child {
                        margin-left: rem(42);

                        @include desktop-down {
                            margin-left: rem(26);
                        }
                    }
                }

                .m-radioNormal {
                    margin-left: rem(42);

                    @include desktop-down {
                        margin-left: rem(26);
                    }

                    &:first-child {
                        margin-left: 0;
                    }
                }
            }

            @include mobile-down {
                .a-heading {
                    text-align: center;
                }
            }
        }

        &_line {
            border-left: 1px solid $anti-flash-white;
            margin-left: rem(80);
            margin-right: rem(80);

            @include desktop-down {
                margin-left: rem(24);
                margin-right: rem(24);
            }
        }

        &_old {
            flex-basis: rem(203);
            flex-shrink: 0;

            @include mobile-down {
                margin-top: rem(12);

                .a-heading {
                    text-align: center;
                }
            }

            .m-radioFill {
                margin-top: rem(12);
            }
        }

        &_questions {
            margin-top: rem(26);

            &_item {

                // @include desktop-down {
                margin-top: rem(26);
                // }

                &.vertical {
                    margin-top: rem(40);

                    &:first-child {
                        margin-top: 0;
                    }
                }
            }
        }

        &_controls {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: rem(22);

            .a-button {
                max-width: rem(220);

                &:last-child {
                    margin-left: rem(32);
                }
            }

            @include desktop-down {
                margin-top: rem(42);
            }
        }

        &_icon {
            width: rem(112);
            height: rem(112);
            margin: rem(24) auto rem(24);
            border-radius: 50%;
            background-color: $white;
            border: 2px solid $pastel-violet;
        }

        &_section {
            margin-top: rem(40);

            &:first-child {
                margin-top: 0;
            }
        }
    }

    &_preview {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        padding: rem(40) rem(150);

        @include desktop-down {
            padding-left: rem(40);
            padding-right: rem(40);
        }

        @include mobile-down {
            padding-left: rem(16);
            padding-right: rem(16);
        }

        @include kiosk {
            padding-left: rem(24);
            padding-right: rem(24);
        }

        &_heading {
            background-color: $pink-lace-op04;
            margin-left: auto;
            margin-right: auto;
            padding: rem(18) rem(24);
            display: flex;
            align-items: center;
            gap: rem(20);
            border-radius: rem(8);
            margin-bottom: rem(8);
        }

        &_table {
            margin-top: rem(20);
            width: 100%;
            border-radius: rem(8) rem(8) 0 0;
            overflow: hidden;

            @include mobile-down {
                border-radius: 0;
            }

            table {
                width: 100%;
                border-spacing: 0;

                @include mobile-down {
                    thead {
                        display: none;
                    }

                    tr td {
                        &:first-child {
                            display: none;
                        }
                    }
                }

                td {
                    border: 1px solid $silver-sand;
                    border-collapse: collapse;

                    &:first-child {
                        border-right-width: 0;
                    }
                }

                tr {
                    &:nth-child(2n) {
                        background-color: $white;
                    }

                    &:nth-child(2n+1) {
                        background-color: $white-smoke;
                    }
                }
            }
        }

        &_th {
            padding: rem(16) rem(8);
            background-color: $imperial-purple;
        }

        &_td {
            padding: rem(8);
        }

        &_tdMobile {
            padding: rem(24) rem(16);
            border-bottom: 1px solid $silver-sand;

            @include mobile-up {
                display: none;
            }
        }

        &_question {
            padding: rem(12) rem(20);
            border-bottom: 1px solid $silver-sand;

            @include mobile-down {
                padding: rem(20) rem(16);
            }

            &:last-child {
                border-bottom: 0;
            }

            .m-radioFill {
                display: inline-block;

                &_info {
                    input {
                        max-width: rem(142);
                    }
                }
            }
        }
    }
}
