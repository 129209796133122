.o-resultCard {
    padding: rem(20) rem(40);

    @include mobile-down {
        padding-left: rem(20);
        padding-right: rem(20);
    }

    &_list {
        margin-top: rem(24);

        &-item {
            @include adjust-flex(space-between, center);

            &:not(&:first-child) {
                margin-top: rem(24);
            }

            .a-text {
                &:nth-child(2) {
                    min-width: rem(120);
                    padding-left: rem(12);
                }
            }
        }
    }
}
