.o-kioskCard {
    $root: &;
    cursor: pointer;
    border: rem(1.4) solid transparent;
    border-radius: rem(4);
    transition: border $transition ease-in-out;
    overflow: hidden;

    &_content {
        padding: rem(12) rem(6);

        &_title {
            .a-text {
                @include text-overflow;
                transition: color $transition ease-in-out;
            }
        }

        &_desc {
            @include adjust-flex(flex-start, flex-start);
            margin-top: rem(8);
            
            .a-icon {
                transition: background-image $transition ease-in-out;
                margin-right: rem(4);
            }
            
            .a-text {
                @include text-overflow;
            }
        }
    }

    &:hover,
    &-selected {
        border-color: $pearly-purple;
        #{$root}_content {
            &_title {
                .a-text {
                    color: $pink-raspberry;
                }
            }

            &_desc {
                .a-icon {
                    background-image: url('~assets/icons/ic_add_location_rounded_purple.svg');
                }
            }
        }
    }
}
