/* stylelint-disable max-nesting-depth */
.o-steps {
    $root: &;
    position: relative;

    &_inside {
        position: relative;
        display: flex;
        flex-direction: column;

        &_item {
            position: relative;

            &_line {
                width: rem(6);
                height: rem(76);
                border-radius: rem(12);
                z-index: 1;
                background-color: $white-op03;
                position: relative;
            }

            &_dot {
                position: absolute;
                left: rem(-9);
                bottom: rem(-9);
                z-index: 2;
                width: rem(24);
                height: rem(24);
                background-color: $plink-light;
                border-radius: 50%;
                border: 2px solid $medium-ruby;
                @include adjust-flex();
            }

            &_text {
                padding-left: rem(34);
                bottom: 3%;
                transform: translateY(50%);
                position: absolute;

                >span {
                    @include font-base(16, 19);
                    font-weight: 600;
                    color: $white;
                    letter-spacing: 0.0015em;
                    opacity: 0.4;
                    @include text-overflow();

                    @include desktop-down {
                        @include font-base(14, 17);
                        font-weight: 400;
                    }
                }
            }

            &_number {
                @include font-base(16, 19);
                font-style: normal;
                font-weight: 600;
                text-align: center;
                letter-spacing: 0.0015em;
                color: $white;

                @include desktop-down {
                    @include font-base(14, 17);
                }
            }

            &-active {

                #{$root}_inside_item_line,
                #{$root}_inside_item_dot {
                    background-color: $orange-yellow;
                }

                #{$root}_inside_item_dot {
                    border: 2px solid $white;

                    &::after {
                        position: absolute;
                        content: '';
                        width: rem(17);
                        height: rem(17);
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        background: url('~assets/icons/check.svg') no-repeat;
                        background-size: contain;
                    }

                    &-current {
                        &::after {
                            content: none;
                        }
                    }
                }

                #{$root}_inside_item_text {
                    >span {
                        color: $orange-yellow;
                        opacity: 1;
                    }
                }
            }
        }
    }



    &-isHorizontal {
        overflow: hidden;
        min-height: rem(70);
        display: flex;
        width: 100%;
        align-items: flex-end;
        justify-content: center;
        padding-bottom: rem(10);

        @include small-mobile {
            min-height: rem(26);
        }

        #{$root}_inside {
            flex-direction: row;
            margin-left: rem(-28);

            &_item {
                display: flex;

                &:first-child {
                    #{$root}_inside_item_line {
                        width: rem(68);

                        @include small-mobile {
                            width: rem(28);
                        }
                    }   
                }

                &:last-child {
                    #{$root}_inside_item_line::after {
                        @include small-mobile {
                            content: '';
                            width: rem(17);
                            height: rem(6);
                            position: absolute;
                            right: rem(-28);
                            border-top-right-radius: rem(12);
                            border-bottom-right-radius: rem(12);
                            background-color: $white-op03;
                        }
                    }
                }

                &_line {
                    height: rem(6);
                    width: rem(101);

                    @include small-mobile {
                        width: rem(60);
                    }
                }

                &_dot {
                    left: inherit;
                    right: rem(-13);
                }

                &_text {
                    padding-left: 0;
                    bottom: initial;
                    top: -100%;
                    width: rem(300);
                    height: rem(40);
                    right: 50%;
                    transform: translate(67%, -115%);
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    >span {
                        @include font-base(14, 20);
                        @include text-overflow();
                    }
                }

                &-active {
                    &:last-child {
                        #{$root}_inside_item_line::after {
                            @include small-mobile {
                                background-color: $orange-yellow;
                            }
                        }
                    }
                }
            }
        }
    }

    &-overload {
        justify-content: flex-start;

        #{$root}_inside {
            margin-left: 0;
        }
    }
}
