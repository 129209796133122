// Text Colors
$white: rgb(255, 255, 255); // #ffffff
$black: rgb(0, 0, 0); // #000000
$smoky-black: rgb(10, 10, 10); // #0a0a0a
$imperial-purple: rgb(101, 0, 53); // #650035
$claret: rgb(120, 12, 62); // #780c3e
$silver-sand: rgb(194, 194, 194); // #c2c2c2
$medium-ruby: rgb(159, 51, 108); // #9f336c
$gainsboro: rgb(222, 223, 224); //#dedfe0
$white-smoke: rgb(245, 245, 245); // #f5f5f5
$battleship-grey: rgb(133, 133, 133); // #858585
$pearly-purple: rgb(183, 102, 145); //#b76691
$queen-pink: rgb(231, 204, 218); //#e7ccda
$anti-flash-white: rgb(240, 240, 240); //#F0F0F0
$fire-engine-red: rgb(198, 30, 30); //#c61e1e
$eerie-black: rgb(26, 26, 26); // #1a1a1a
$orange-yellow: rgb(248, 205, 108); // #F8CD6C
$plink-light: rgb(231, 204, 218); // #E7CCDA
$dark-liver: rgb(77, 77, 77); //#4d4d4d
$dark-tangerine: rgb(244, 172, 10); // #f4ac0a
$jet: rgb(51, 51, 51); // #333333
$snow: rgb(251, 247, 249); //#fbf7f9
$maroon-x11: rgb(179, 34, 104); //#b32268
$french-plum: rgb(135, 9, 87); //#870957
$pastel-violet: rgb(207, 153, 181); //#cf99b5
$pink-lace: rgb(255, 224, 240); //#ffe0f0
$dark-liver: rgb(77, 77, 77); //#4d4d4d
$eerie-black: rgb(26, 26, 26); //#1a1a1a
$pearly-purple: rgb(183, 102, 145); //#b76691
$pink-raspberry: rgb(135, 0, 71); //#870047
$mustard: rgb(255, 210, 73); //#ffd249
$deep-champagne: rgb(251, 222, 157); //#fbde9d
$vivid-malachite: rgb(4, 205, 24); //#04cd18
$raisin-black: rgb(32, 32, 32); //#202020
// Overlays
$white-op03: rgba($white, 0.3);
$pink-lace-op04: rgba($pink-lace, 0.4);
$black-op25: rgba($black, 0.25);

// Linears
