.p-waitingScreen {
    position: relative;

    &_item {
        img {
            width: 100vw;
            min-height: 100vh;
            object-fit: contain;
        }
    }
}
