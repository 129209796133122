.o-footer {
    $root: &;

    flex-grow: 1;
    background-color: $dark-liver;
    padding: rem(22) rem(168) rem(22) rem(40);
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include break-max(1101px) {
        padding-right: rem(40);
    }

    @include small-mobile {
        display: block;
        padding: rem(32) rem(16);
    }

    &_top {
        @include adjust-flex(space-between, center);

        @include small-mobile {
            display: block;
        }

        &-alignTop {
            align-items: flex-start;
        }
    }

    &_left {
        flex: 0 0 60%;
    }

    &_donors {
        margin-top: rem(8);
        @include adjust-flex(flex-start, flex-end);
        flex-wrap: wrap;

        &-image {
            width: 100%;
            max-width: rem(93);
            margin: 0 rem(6);

            @include small-mobile {
                margin-right: 0;
            }
        }

        &-subText {
            width: 100%;
        }
    }

    &_menu {
        margin-top: rem(12);
        @include adjust-flex(space-between, center);
        flex-wrap: wrap;

        &_desktop {
            @include break-max(1101px) {
                display: none;
            }
        }

        &_kiosk {
            display: none;

            @include break-max(1101px) {
                display: flex;
                flex-wrap: wrap;
            }
        }
    }

    &_item {
        @include small-mobile {
            flex: 0 0 50%;
            margin-bottom: rem(4);
        }
    }

    &_right {
        flex-basis: rem(151);
        display: flex;
        flex-direction: column;

        &_languages {
            position: relative;
            width: 100%;
            max-width: rem(140);
            cursor: pointer;
            margin-bottom: rem(8);

            @include mobile-down {
                margin-bottom: rem(16);
            }

            &_current {
                position: relative;
                border: 1px solid $white;
                background-color: $raisin-black;
                border-radius: rem(6);
                overflow: hidden;
                padding: rem(8);
                width: 100%;
                display: flex;
                align-items: center;

                &::after {
                    content: '';
                    width: rem(8);
                    height: rem(8);
                    right: rem(12);
                    top: 50%;
                    background-image: url('~assets/images/arrow-down-white.svg');
                    background-repeat: no-repeat;
                    background-size: contain;
                    transition: transform 0.2s ease;
                    transform: translateY(-50%) rotate(180deg);
                    position: absolute;
                }

                &-active {
                    &::after {
                        transform: translateY(-50%) rotate(0deg);
                    }
                }

                img {
                    flex-grow: 0;
                    margin-right: rem(4);
                }
            }

            &_options {
                border: 1px solid $white;
                background-color: $raisin-black;
                border-radius: rem(6);
                overflow: hidden;
                padding: rem(4) rem(8);
                width: 100%;
                position: absolute;
                top: rem(42);
                display: none;
                z-index: 1;

                &-active {
                    display: block;
                }
            }

            &_item {
                display: flex;
                align-items: center;
                margin-top: rem(4);
                margin-bottom: rem(4);
                width: 100%;
                position: relative;

                &:hover {
                    #{$root}_right_languages_item_dot {
                        display: block;
                    }
                }

                img {
                    flex-grow: 0;
                    margin-right: rem(6);
                }

                &_dot {
                    display: none;
                    background-color: $vivid-malachite;
                    width: rem(4);
                    height: rem(4);
                    border-radius: 50%;
                    box-shadow: 0 0 rem(40) $black-op25;
                    position: absolute;
                    right: rem(6);
                    top: 50%;
                    transform: translateY(-50%);

                    &-active {
                        display: block;
                    }
                }
            }
        }

        &_qr {
            @include mobile-down {
                display: none;
            }
        }
    }

    &_image {
        max-width: rem(140);
        width: 100%;
    }

    &_link {
        margin-top: rem(8);
        @include adjust-flex(end, center);

        .a-icon {
            margin-right: rem(4);
        }
    }
}
