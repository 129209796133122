.a-button {
    $root: &;
    @include reset-button();
    cursor: pointer;
    background-color: $claret;
    border: rem(1) solid $medium-ruby;
    @include adjust-flex(center, center);
    border-radius: rem(12);
    width: 100%;
    transition: $transition-long ease;
    white-space: nowrap;

    &-rightIcon {
        flex-direction: row-reverse;

        .a-icon {
            margin-left: rem(10);
        }
    }

    &:not(:disabled) {
        &:hover {
            opacity: 0.8;
        }
    }

    .a-icon {
        margin-right: rem(10);
    }

    &:has(#{$root}_loadingIcon) {
        @include adjust-flex(center, center);
    }

    &_loadingIcon {
        width: rem(28);
    }

    &-secondary {
        @include adjust-flex(space-between, center);
        padding-left: rem(24);
        padding-right: rem(24);
        border: 2px solid $medium-ruby;

        .a-icon {
            margin-right: 0;
        }
    }

    &-outline {
        padding-left: rem(24);
        padding-right: rem(24);
        border: rem(2) solid $silver-sand;
        background-color: transparent;
    }

    &-primary {
        @include adjust-flex(space-between, center);
        border: rem(2) solid $silver-sand;
        background-color: $anti-flash-white;
        padding-left: rem(24);
        padding-right: rem(24);
    }

    &-bgMustard {
        @include adjust-flex(space-between, center);
        border-radius: rem(60);
        border: transparent;
        background-color: $mustard;
        padding-left: rem(32);
        padding-right: rem(32);
        height: rem(72);
    }

    &-h72 {
        height: rem(72);
        border-radius: rem(8);
    }

    &-h48 {
        height: rem(48);
    }

    &-h60 {
        height: rem(60);

        @include mobile-down {
            height: rem(54);
        }
    }

    &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
        border: 1px solid $gainsboro;
        backdrop-filter: blur(10px);
        background: $white-smoke;

        .a-text {
            color: rgba($black, 0.25);
        }

        .a-icon {
            filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(287deg) brightness(104%) contrast(104%);
        }
    }
}
