.t-mainLayout {
    display: flex;
    flex-direction: column;

    &_content {
        position: relative;
        background-color: $snow;
        min-height: 100vh;

        &:has(.p-select-kiosk) {
            min-height: rem(1400);

            @include tablet-down {
                min-height: rem(1164);
            }
        }
    }

    &_footer {
        flex-grow: 1;
    }
}
