.a-heading {
    $root: &;
    margin: 0;

    &-h2 {
        @include font-base(36, 48);

        @include tablet-down {
            @include font-base(32, 42);
        }

        @include mobile-down {
            @include font-base(28, 36);
        }

        &#{$root}-lg {
            @include font-base(40, 48);

            @include tablet-down {
                @include font-base(34, 42);
            }
    
            @include mobile-down {
                @include font-base(30, 38);
            }    
        }
    }

    &-h3 {
        @include font-base(32, 48);

        @include tablet-down {
            @include font-base(30, 40);
        }

        @include mobile-down {
            @include font-base(26, 32);
        }
    }

    &-h4 {
        @include font-base(28, 36);

        @include mobile-down {
            @include font-base(24, 32);
        }
    }

    &-h5 {
        @include font-base(24, 32);

        @include mobile-down {
            @include font-base(20, 28);
        }
    }

    &-h6 {
        @include font-base(20, 28);

        @include desktop-down {
            @include font-base(16, 20); 
        }

        @include mobile-down {
            @include font-base(18, 24);
        }
    }

    @include letter-spacing();
    @include style-font-weight();
    @include style-general();
    @include style-color();
}
