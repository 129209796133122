.p-select-kiosk {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    

    &_banner {
        flex-shrink: 0;
        @include adjust-flex;
        height: rem(400);
        background-color: $french-plum;
        background-size: calc(152 / 768 * 100%);
        background-image: url('~assets/images/select-kiosk-left.svg'), url('~assets/images/select-kiosk-right.svg');
        background-repeat: no-repeat;
        background-position: left rem(24) bottom rem(24), right rem(24) top rem(24);

        @include max-desktop {
            background-size: 12%;
        }

        @include tablet-down {
            height: rem(280);
        }

        @include kiosk {
            height: rem(352);
        }

        &_leftLogo {
            width: 26.4%;
            margin-right: rem(40);

            @include kiosk {
                width: 34.4%;
            }
        }

        &_rightLogo {
            width: 12.2%;

            @include kiosk {
                width: 17.2%;
            }
        }
    }

    &_main {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: rem(40) rem(60);

        @include kiosk {
            padding: rem(40);
        }
    }

    &_wrapper {
        position: relative;
        flex-grow: 1;
    }

    &_list {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        overflow-y: auto;
        @include scroll-bars(8, $dark-tangerine, $snow);

        &-item {
            flex-basis: calc(100% / 3);
            padding: rem(12);
            @include mobile-down {
                flex-basis: 100%;
            }
        }
    }

    &_button {
        max-width: rem(220);
        margin: rem(32) auto 0;
    }
}
