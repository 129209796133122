.p-waitingConfirm {
    background: url('~assets/images/bg_waiting.png') no-repeat;
    padding: rem(100) rem(200) rem(100);
    min-height: 70vh;

    @include max-desktop-down {
        padding: rem(28) rem(150) rem(84);
    }

    @include desktop-down {
        padding: rem(80) rem(60) rem(200);
    }

    @include small-mobile {
        padding: rem(26);
    }

    &_wrapImages {
        @include adjust-flex(center, center);
    }

    &_imageFirst {
        max-width: rem(330);
        width: 100%;
        margin-right: rem(50);

        @include max-desktop-down {
            max-width: rem(238);
        }
    }

    &_imageSecond {
        max-width: rem(170);
        width: 100%;

        @include max-desktop-down {
            max-width: rem(109);
        }
    }

    &_title {
        margin-top: rem(50);

        @include max-desktop-down {
            margin-top: rem(26);
        }

        @include desktop-down {
            margin-top: rem(100);
        }

        @include small-mobile {
            margin-top: rem(40);
        }
    }

    &_content {
        margin-top: rem(24);
        margin-bottom: rem(32);

        @include max-desktop-down {
            margin-top: rem(16);
            margin-bottom: rem(20);
        }

        @include desktop-down {
            margin-top: rem(24);
            margin-bottom: rem(32);
        }
    }

    &_button {
        max-width: max-content;
        margin-left: auto;
        margin-right: auto;
    }
}
