.m-radioNormal {
    cursor: pointer;
    $root: &;
    @include adjust-flex(flex-start, center);

    input {
        -webkit-user-select: text;
    }

    &_input {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        display: none;

        &:checked {

            +#{$root}_holder {
                border: 0;
                background: url('~assets/icons/ic_checkbox.svg') no-repeat center / cover;
            }

            #{$root}-iconCircle &+#{$root}_holder {
                background: url('~assets/icons/ic_checkbox_circle.svg') no-repeat center / cover;
            }

            ~#{$root}_label {
                color: $claret;
            }
        }
    }


    &_holder {
        width: rem(24);
        height: rem(24);
        border-radius: 50%;
        border: 1px solid $battleship-grey;
        position: relative;
        flex-shrink: 0;

        @include mobile-down {
            width: rem(20);
            height: rem(20);
            right: rem(10);
        }
    }

    &_label {
        padding-left: rem(12);
        @include font-base(18, 24);
        letter-spacing: 0.0015em;
        color: $battleship-grey;

        @include mobile-down {
            @include font-base(16, 20);
        }
    }

    &-required {
        #{$root}_holder {
            border-color: $fire-engine-red;
        }

        #{$root}_label {
            color: $fire-engine-red;
        }
    }
}

.m-radioFill {
    $root: &;
    cursor: pointer;

    input {
        -webkit-user-select: text;
    }

    &_label {
        display: block;
        margin-bottom: rem(8);
        @include font-base(18, 24);
        letter-spacing: 0.0015em;
        color: $smoky-black;

        @include small-mobile {
            @include font-base(14, 20);
        }
    }

    &_wrap {
        @include adjust-flex(flex-start, center);
        position: relative;
    }

    &-checked {

        #{$root}_info {
            background-color: $white;
            border-color: $pearly-purple;

            &::before {
                position: absolute;
                top: -3px;
                left: -3px;
                right: -3px;
                bottom: -3px;
                pointer-events: none;
                border: 2px solid $queen-pink;
                border-radius: rem(10);
                content: '';
            }

            input {
                pointer-events: initial;
                color: $claret;
            }
        }

    }

    &_check {
        margin-right: rem(8);
    }

    &_info {
        flex: 1;
        border: 1px solid $silver-sand;
        border-radius: 8px;
        height: rem(48);
        display: flex;
        align-items: center;
        padding: 0 rem(20);
        background-color: $white;
        @include font-base(20, 28);
        letter-spacing: 0.0015em;
        font-weight: 600;
        position: relative;
        box-shadow: 0 6px 16px rgba(134, 0, 82, 0.09);

        @include small-mobile {
            height: rem(40);
        }
    }

    input {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        outline: none;
        width: 100%;
        border: 0;
        min-width: rem(60);
        font-size: rem(20);
        font-weight: bold;
        background-color: transparent;
        pointer-events: none;
        padding: 0 rem(20) 0 0;

        @include mobile-down {
            font-size: rem(16);
        }
    }

    &_unit {
        white-space: nowrap;
        padding-left: rem(20);
        border-left: 1px solid $silver-sand;
        height: 100%;
        display: flex;
        align-items: center;
        color: $smoky-black;

        @include small-mobile {
            height: rem(40);
            @include font-base(14, 20);
        }
    }

    &-required {
        .m-radioNormal_holder {
            border-color: $fire-engine-red;
        }

        #{$root}_info {
            border-color: $fire-engine-red;
            background-color: rgba($fire-engine-red, 0.1);

            * {
                color: $fire-engine-red !important;
            }
        }

        #{$root}_unit {
            border-color: $fire-engine-red;
        }
    }
}

.m-radioText {
    cursor: pointer;
    $root: &;
    @include adjust-flex(flex-start, center);
    position: relative;

    input {
        -webkit-user-select: text;
    }

    &_input {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        display: none;

        &:checked {
            +#{$root}_holder {
                display: block;
            }

            ~#{$root}_label {
                border-color: $pearly-purple;
                color: $claret;
                box-shadow: 0 6px 16px rgba(134, 0, 82, 0.09);

                &::before {
                    position: absolute;
                    top: -3px;
                    left: -3px;
                    right: -3px;
                    bottom: -3px;
                    pointer-events: none;
                    content: '';
                    border-radius: rem(10);
                    border: 2px solid $queen-pink;
                }
            }
        }
    }

    &_holder {
        width: rem(20);
        height: rem(20);
        display: none;
        background: url('~assets/icons/ic_checkbox.svg') no-repeat center / cover;
        position: absolute;
        top: 50%;
        right: rem(16);
        transform: translateY(-50%);
        z-index: 1;

        @include mobile-down {
            width: rem(20);
            height: rem(20);
            right: rem(10);
        }
    }

    &_label {
        position: relative;
        border-radius: rem(8);
        height: rem(48);
        display: flex;
        flex: 1;
        align-items: center;
        font-weight: 600;
        @include font-base(18, 24);
        letter-spacing: 0.0015em;
        color: $battleship-grey;
        padding: 0 rem(40) 0 rem(12);
        border: 1px solid $silver-sand;
        background-color: $white;

        @include small-mobile {
            height: rem(40);
            padding-right: rem(28);
            @include font-base(14, 20);
        }
    }

    &-required {
        #{$root}_label {
            border-color: $fire-engine-red;
            background-color: rgba($fire-engine-red, 0.1);
            color: $fire-engine-red;
        }
    }

    &-disabled {
        #{$root}_label {
            color: $silver-sand;
        }

        #{$root}_input {
            &:checked {
                +#{$root}_holder {
                    display: none;
                }

                ~#{$root}_label {
                    color: $silver-sand;
                    border-color: $silver-sand;
                    box-shadow: none;

                    &::before {
                        content: none;
                    }
                }
            }
        }
    }
}

.m-radioImage {
    position: relative;
    cursor: pointer;
    $root: &;

    input {
        -webkit-user-select: text;
    }

    &_input {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        display: none;

        &:checked {
            +#{$root}_holder {
                display: block;
            }

            ~#{$root}_label {
                border-color: $pearly-purple;
                color: $claret;
                box-shadow: 0 6px 16px rgba(134, 0, 82, 0.09);

                &::before {
                    position: absolute;
                    top: -3px;
                    left: -3px;
                    right: -3px;
                    bottom: -3px;
                    pointer-events: none;
                    content: '';
                    border-radius: rem(10);
                    border: 2px solid $queen-pink;
                }
            }
        }
    }

    &_label {
        @include adjust-flex(center, center);
        flex-direction: column;
        padding: rem(19) rem(20) rem(15) rem(20);
        font-weight: 600;
        @include font-base(20, 28);
        letter-spacing: 0.0015em;
        color: $battleship-grey;
        border: 1px solid $silver-sand;
        border-radius: rem(8);
        position: relative;

        img {
            width: rem(48);
            height: rem(48);
            margin-bottom: rem(12);
        }
    }

    &_holder {
        width: rem(20);
        height: rem(20);
        display: none;
        background: url('~assets/icons/ic_checkbox.svg') no-repeat center / cover;
        position: absolute;
        top: rem(8);
        right: rem(12);
    }

    &-required {
        #{$root}_label {
            color: $fire-engine-red;
        }

        #{$root}_label {
            border-color: $fire-engine-red;
        }
    }
}
