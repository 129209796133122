.t-notfound {
    @include adjust-flex(center, center);
    flex-direction: column;
    min-height: 100vh;

    &_img {
        width: 100%;
        max-width: rem(478);
        @include mobile-down {
            padding: rem(20);
        }
    }

    &_button {
        width: rem(173);
        margin-top: rem(100);
        margin-left: auto;
        margin-right: auto;
        @include mobile-down {
            margin-top: rem(50);
        }
    }
}
