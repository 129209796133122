.p-searchResult {
    padding: rem(40) rem(60);
    width: 100%;
    margin: 0 auto;

    @include break-min(1920px) {
        width: 70%;
    }

    @include mobile-down {
        padding: rem(24) rem(16);
    }

    @include kiosk {
        padding-left: rem(24);
        padding-right: rem(24);
    }

    &_logo {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: rem(40);
    }

    &_logo1 {
        margin-right: rem(16);
        width: rem(238);
        @include mobile-down {
            width: rem(180);
        }

        img {
            width: 100%;
        }
    }

    &_logo2 {
        margin-left: rem(16);
        width: rem(109);
        @include mobile-down {
            width: rem(80);
        }

        img {
            width: 100%;
        }
    }

    &_general {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: rem(40);
        @include kiosk {
            flex-direction: column;
        }
    }

    &_avatar {
        flex-shrink: 0;
        width: 100%;
        max-width: rem(188);

        @include tablet-down {
            display: none;
        }
    }

    &_infoResult {
        padding: rem(16) rem(20);
        background-color: $plink-light;
        border-radius: rem(8);
        margin-left: rem(32);
        flex: 1;

        @include tablet-down {
            margin-left: 0;
        }
        @include kiosk {
            margin-left: 0;
            margin-top: rem(40);
        }

        &_title {
            margin-bottom: rem(20);
        }

        &_name {
            margin-bottom: rem(8);
        }

        &_diagnostics {
            margin-bottom: rem(10);
        }

        &_diagnosticValue {
            margin-left: rem(18);
        }

        &_refer {
            @include adjust-flex(flex-start, center);

            .a-icon {
                margin-right: rem(8);
            }
        }
    }

    // content
    &_content {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding-top: rem(40);

        @include tablet-down {
            padding-top: rem(24);
        }

        &_item {
            overflow: hidden;

            &-wrap {
                width: 100%;
                background-color: $white;

                @include tablet-down {
                    width: 100%;
                }
            }
    
            &-information {
                border: 1px solid $silver-sand;
                border-radius: rem(8);
            }

            &-diagnostics {
                border: 1px solid $silver-sand;
                border-radius: rem(8);
                margin-top: rem(24);
                margin-bottom: rem(40);
            }

            &-cart {
                border-bottom: 1px solid $silver-sand;

                &:last-child {
                    border-bottom: 0;
                }
            }
        }
    }

    // Button
    &_buttons {
        margin-top: rem(40);
        @include adjust-flex(center, center);

        &-nt1 {
            margin-right: rem(40);

            @include mobile-down {
                margin-right: rem(20);
            }
        }
    }

    &_advice {
        margin-top: rem(24);
        background-color: $deep-champagne;
        padding: rem(12);
        border-radius: rem(4);

        &_content {
            margin-top: rem(12);

            ul {
                padding-left: rem(30);

                li {
                    line-height: rem(24);
                    font-size: rem(16);
                }
            }
        }

    }
}
