.o-formField {
    $root: &;

    @include small-mobile {
        .a-heading {
            text-align: center;
        }
    }

    &-horizontal {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: rem(64);

        .a-heading {
            flex-basis: rem(356);
        }
        @include desktop-down {
            display: block;
        }

        #{$root} {
            &_body {
                margin-top: 0;
                flex: 0 0 50%;
                margin-left: rem(80);
                flex-grow: 1;
                margin-right: rem(-11);

                @include desktop-down {
                    margin-left: rem(-11);
                    margin-top: rem(18);
                }

                @include mobile-down {
                    margin-left: rem(-4);
                    margin-right: rem(-4);
                }
            }

            &_item {
                flex-basis: 33.333333%;
                padding: 0 rem(11);

                @include mobile-down {
                    padding: 0 rem(4);
                }
            }
        }

        @include small-mobile {
            display: block;
            #{$root}_body {
                margin-top: rem(12);
            }
        }
    }

    &_body {
        margin-top: rem(16);
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        margin-left: rem(-16);
        margin-right: rem(-16);

        @include mobile-down {
            margin-top: rem(12);
            margin-left: rem(-4);
            margin-right: rem(-4);
        }

        &-three {
            #{$root}_item {
                flex-basis: 33.333333%;
                @include mobile-down {
                    flex-basis: 50%;
                }
            }
        }
    }

    &_item {
        flex-basis: 50%;
        padding: rem(8) rem(16);
        @include kiosk {
            padding-left: rem(12);
            padding-right: rem(12);
        }
        @include mobile-down {
            padding: rem(8) rem(4);
        }
    }

    &-preview {
        #{$root} {
            &_body {
                margin-left: 0;
                margin-right: 0;

                &-two {
                    #{$root}_item {
                        flex-basis: 100%;
                        margin-top: rem(16);
                        margin-left: 0;

                        &:first-child {
                            margin-top: 0;
                        }
                    }
                }
            }

            &_item {
                flex-basis: unset;
                padding: 0;
                margin-left: rem(42);

                &:first-child {
                    margin-left: 0;
                }
                @include desktop-down {
                    margin-left: rem(26);
                }
            }
        }

        @include mobile-down {
            .a-heading {
                text-align: left;
            }
        }
    }

    &-fullLine {
        #{$root}_item {
            @include mobile-down {
                flex-basis: 100%;
            }
        }
    }
}
