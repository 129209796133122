.o-modal {
    $root: &;
    border: 0;
    height: 100%;
    outline: 0;
    position: relative;
    width: 100%;

    &:focus {
        outline: 0;
    }
 
    &_close {
        @include reset-button();
        background-color: transparent;
        padding: 0;
        position: absolute;
        right: rem(16);
        top: rem(16);
        z-index: $z-1;
    }

    &_main {
        align-items: center;
        display: flex;
        height: 100%;
        max-width: rem(1366);
        margin-left: auto;
        margin-right: auto;
        overflow: hidden;
        padding-left: rem(20);
        padding-right: rem(20);
        width: 100%;
    }

    &_wrapper {
        background: $white;
        box-shadow: 0 rem(8) rem(20) rgba($black, 0.25);
        border-radius: rem(12);
        padding: rem(40) rem(64);
        display: flex;
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
        max-height: calc(100vh - #{rem(20)});
        overflow: hidden;
        position: relative;
        width: 100%;
        z-index: 103;
    }

    &_body {
        flex: 1;
    }

    &-notification {
        #{$root} {
            &_wrapper {
                max-width: rem(550);
            }
        }
    }

    &-maxWith428 {
        #{$root} {
            &_wrapper {
                max-width: rem(428);
            }
        }
    }

    &_iconError {
        display: flex;
        justify-content: center;
        margin-bottom: rem(16);
    }

    &_button {
        display: flex;
        justify-content: center;
        margin-top: rem(24);

        button {
            width: auto;
        }
    }
}

.ReactModal__Overlay {
    opacity: 0;
    transform: translateX(-100px);
    transition: all 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
    transform: translateX(0);
}

.ReactModal__Overlay--before-close {
    opacity: 0;
    transform: translateX(-100px);
}

.ReactModal__Overlay {
    background: rgba($eerie-black, 0.5);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    position: fixed;
    transform: translateY(-100%);
    transition: all 500ms ease-in-out;
    z-index: $z-modal;

    &::after {
        background: rgba($eerie-black, 0.5);
        content: '';
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 102;
    }

    &--open {
        overflow: hidden;
    }

    &--after-open {
        opacity: 1;
        transform: translateY(0);
    }

    &--before-close {
        opacity: 0;
        transform: translateY(-100%);
    }
}

.o-popup_overlay {
    &-popupImage {
        z-index: 0;
    
        &::after {
            z-index: 0;
        }
    }
}
