.a-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    img {
        display: none;
    }

    &-inherit {
        background-size: inherit;
    }

    &-initial {
        background-size: initial;
    }

    &-contain {
        background-size: contain;
    }

    &-logo {
        @include aspect-ratio(155, 56);
    }

    &-1x1 {
        @include aspect-ratio;
    }

    &-4x3 {
        @include aspect-ratio(4, 3);
    }

    &-16x9 {
        @include aspect-ratio(16, 9);
    }

    &-93x28 {
        @include aspect-ratio(93, 28);
    }

    &-94x74 {
        @include aspect-ratio(94, 74);
    }

    &-200x113 {
        @include aspect-ratio(200, 113);
    }

    &-188x156 {
        @include aspect-ratio(188, 156);
    }

    &-478x361 {
        @include aspect-ratio(478, 361);
    }

    &-203x74 {
        @include aspect-ratio(203, 74);
    }

    &-768x280 {
        @include aspect-ratio(768, 280);
    }

    &-238x86 {
        @include aspect-ratio(238, 86);
    }

    &-109x86 {
        @include aspect-ratio(109, 86);
    }

    &-1366x768 {
        @include aspect-ratio(1366, 768);
    }

    &-768x1366 {
        @include aspect-ratio(768, 1366);
    }
}
